<template>
    <div class="supported_ceil_cont" :id="`support-work-time-${position}`">
        <div class="column__cont" v-bind:style="{
            'border-bottom' : calendarSupportedHours.length > 0 ? 
                '1px grey solid' : 'none' 
        }">
            <Item v-for="index in tableHeader.columnCount"
                :key="'supported-work-time-' + index"
                :columnCount="tableHeader.columnCount"
                :hours="calendarSupportedHours"
                :index="index"
                :scale="getColumnScale(index)"
                :isSelected="selectedDateIndex == index"
            />
        </div>
        <div class="label__cont" v-if="calendarSupportedHours.length > 0">
            <div class="label">Old calendar working time</div>
        </div>
    </div>  
</template>

<script>
import Item from './Item.vue'

export default {
    name: "SupportedWorkTime",
    props: {
        position : {
            type: String,
            required: true,
            validator(value){
                return ['top', 'bottom'].includes(value)
            }
        }
    },
    components: {
        Item,
    },
    computed: { 
        tableHeader(){
            return this.$store.getters.tableHeader
        },
        calendarSupportedHours(){
            let supportedHours = this.$store.getters.calendarSupportedHours
            let workHours = this.$store.getters.calendarWorkHours
            let workStartHour = workHours[0]
            let workEndHour = workHours[workHours.length - 1] 
            if (this.position == "top"){
                return supportedHours.filter(hour => hour < workStartHour)
            }
            if (this.position == "bottom"){
                return supportedHours.filter(hour => hour > workEndHour)
            }
            return supportedHours
        },
        selectedDateIndex(){
            return this.tableHeader.leftSide().length + 1
        }
    },
    // watch: {
    //     "calendarSupportedHours.length": {
    //         handler: (newValue, oldValue) => {

    //         },
    //         deep: true,
    //     }
    // },
    methods: {
        getColumnWidth(isSelected) {
            let ceilSize = this.$store.getters.tableCeilSize
            if (isSelected) {
                return ceilSize.selectedWidth
            } else {
                return ceilSize.defaultWidth
            }
        },
        getColumnScale(columnIndex) {
            if (columnIndex == this.selectedDateIndex) {
                return this.tableHeader.selectedItem().scale
            }
            columnIndex -= 1
            let leftSide = this.tableHeader.leftSide()
            if (columnIndex < leftSide.length){
                return leftSide[columnIndex].scale
            } else {
                let rightSide = this.tableHeader.rightSide()
                return rightSide[columnIndex - leftSide.length - 1].scale
            }
        }
    },
}
</script>

<style scoped>
.column__cont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 300px;
    border: 1px solid grey;
    border-top: none;
}

.column__cont > * + * {
    border-left: 1px solid grey;
}

.supported_ceil_cont {
    
    min-width: 300px;
    position: relative;

}

.supported_ceil_cont > .label__cont {    
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.label__cont > .label {
    font-size: 18px;
    color: grey;
}

</style>