<template>
    <div class="supported_work_time"
	v-if="scale > 0.2"
    v-bind:class="{
        selected : isSelected
    }"
    v-bind:style="{
        width : getColumnWidth(this.isSelected) * scale + 'px',
		opacity: scale,
    }">
		<transition-group name="supported">
			<div class="supported__cell"
				v-for="hour in hours"
				:key="`column_${index}_cell_${hour}`">
			</div>
		</transition-group>
    </div>
</template>

<script>

export default {
    name: "Item",
    props: {
        index: Number,
        columnCount: Number,
        isSelected: Boolean,
		hours: Array,
		scale: Number,
    },
    methods: {
        getColumnWidth(isSelected) {
            let ceilSize = this.$store.getters.tableCeilSize
            if (isSelected) {
                return ceilSize.selectedWidth
            } else {
                return ceilSize.defaultWidth
            }
        },
    },
}
</script>

<style>

</style>

<style scoped>
.selected{
    min-width: 50px;
}
.supported_work_time{
    position: relative;
    min-width: 18px;
}
.supported__cell{
    height: 54px;

    background: #f3e9e8;
    
    animation: 0.5s history;
}

@keyframes history {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: 54px;
        opacity: 1;
    }
}

.supported-enter-active,
.supported-leave-active {    
    transition: all 0.55s ease;
}

.supported-enter-to,
.supported-leave-from {
    height: 54px;
    opacity: 1;
}

.supported-enter-from,
.supported-leave-to {
    height: 0;
    opacity: 0;
}



</style>